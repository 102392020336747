import React from 'react';

function Drinks() {
  function teste() {
    window.location.href = 'https://www.google.com';
  }
  return (
    <button type="button" onClick={ teste }>
      Teste
    </button>
  );
}

export default Drinks;
